<template>
    <div>
        <!-- 1 -->
        <b-card no-body class="mb-4">
            <template v-if="!readOnly">
                <Header />
                <hr class="mb-2" />
            </template>
            <!-- Search Control -->
            <div class="mb-2 d-flex justify-content-between">
                <b-form-group class="mb-2 w-25">
                    <b-input-group>
                        <b-form-input
                            size="sm"
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            placeholder="Type to Search"
                        ></b-form-input>

                        <b-input-group-append>
                            <b-button variant="danger" size="sm" :disabled="!filter" @click="filter = ''"
                                >Clear</b-button
                            >
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <div class="d-flex align-items-center" v-if="!readOnly">
                    <b-button variant="primary" class="mr-2" v-b-modal.modal-reference>Add Reference</b-button>
                </div>
            </div>
            <!-- End Section -->

            <!-- Data Table -->
            <b-table
                style="height: 250px"
                show-empty
                responsive
                small
                class="mb-0"
                head-variant="light"
                :items="references"
                :filter="filter"
                :fields="fields"
                hover
            >
                <!-- <template #cell(id)="data"> #{{ data.item.id }} </template> -->
                <template #cell(createDate)="data"> {{ data.item.createDate.split('T')[0] }} </template>
                <template #cell(isActive)="data">
                    <b-badge variant="success" v-if="data.item.isActive">Active</b-badge>
                    <b-badge variant="danger" v-else>Inactive</b-badge>
                </template>
                <template #cell(action)="data">
                    <b-dropdown variant="primary" id="dropdown-1" text="Actions" class="">
                        <b-dropdown-item @click="showEditModal(data)">Edit Reference</b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>
            <!-- End Section -->

            <!-- Pagination -->
            <div class="p-3 d-flex align-items-center">
                <b-form-group
                    label="Per page"
                    label-for="per-page-select"
                    label-cols-sm="6"
                    label-cols-md="4"
                    label-cols-lg="9"
                    label-size="sm"
                    label-class="fw-medium"
                    class="mb-0"
                >
                    <b-form-select
                        id="per-page-select"
                        v-model="perPage"
                        :options="pageOptions"
                        size="sm"
                    ></b-form-select>
                </b-form-group>
                <div class="ml-auto">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="references.length"
                        :per-page="perPage"
                        align="fill"
                        class="my-0"
                    ></b-pagination>
                </div>
            </div>
            <!-- End Section -->
        </b-card>

        <b-modal
            id="modal-reference"
            hide-footer
            centered
            :title="isEditing ? 'Edit reference' : 'Add a new reference'"
            @close="clearData"
        >
            <b-form @submit.prevent="addReference">
                <b-form-group label="Relation Type" label-class="fw-medium mb-1" label-for="referenceRelationType">
                    <b-form-select
                        required
                        id="referenceRelationType"
                        :options="[{ id: null, description: 'Please select an option' }, ...relationTypes]"
                        text-field="description"
                        value-field="id"
                        v-model="data.relationTypeId"
                    ></b-form-select>
                </b-form-group>
                <b-form-group label="Name" label-class="fw-medium mb-1" label-for="referenceName">
                    <b-form-input required id="referenceName" v-model="data.name" placeholder="Enter a name" />
                </b-form-group>
                <b-form-group label="Contact Number" label-class="fw-medium mb-1" label-for="referenceContactNumber">
                    <b-form-input
                        required
                        id="referenceContactNumber"
                        v-model="data.contactNumber"
                        placeholder="Enter a contact number"
                    />
                </b-form-group>
                <b-form-group label="Address" label-class="fw-medium mb-1" label-for="referenceAddress">
                    <b-form-input
                        required
                        id="referenceAddress"
                        v-model="data.address"
                        placeholder="Enter an address"
                    />
                </b-form-group>
                <b-button block variant="primary" type="submit" :disabled="loadingReference">Save Reference</b-button>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/customers/Header.vue';
export default {
    name: 'Customer',
    components: {
        Header,
    },
    props: {
        new: Boolean,
        readOnly: Boolean,
    },
    data: () => ({
        filter: null,
        fields: [
            {
                key: 'customerId',
                label: 'Customer ID',
            },
            {
                key: 'name',
                label: 'Name',
            },
            {
                key: 'contactNumber',
                label: 'Contact Number',
            },
            {
                key: 'address',
                label: 'Address',
            },
            {
                key: 'relationTypeDescription',
                label: 'Relation Type',
            },
            {
                key: 'action',
                label: 'Actions',
            },
        ],

        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 20, 40, { value: 100, text: 'Show a lot' }],
        loadingReference: false,
        data: {
            name: null,
            relationTypeId: null,
            contactNumber: null,
            address: null,
        },
        isEditing: false,
        relationTypes: [],
    }),
    methods: {
        onRowClicked(item) {
            this.$router.push(`/customers/${item.id}`);
        },
        clearData() {
            this.data = {
                name: null,
                relationTypeId: null,
                contactNumber: null,
                address: null,
            };
        },
        async addReference() {
            this.loadingReference = true;
            try {
                if (!this.isEditing) {
                    await this.$store.dispatch('customer/references/add_reference', {
                        customerId: this.customer.id,
                        ...this.data,
                    });
                } else {
                    await this.$store.dispatch('customer/references/update_reference', {
                        customerId: this.customer.id,
                        ...this.data,
                    });
                }

                this.$bvModal.hide('modal-reference');
                this.clearData();
                await this.$store.dispatch('customer/get_customer', this.$route.params.customer);
            } catch (error) {
                this.loadingReference = false;
            }

            this.loadingReference = false;
        },
        showEditModal(data) {
            this.isEditing = true;
            this.data = data.item;
            this.$bvModal.show('modal-reference');
        },
        async getRelationTypes() {
            const response = await this.$store.dispatch('customer/references/get_relationsTypes');
            this.relationTypes = response?.dataResult ?? [];
        },
    },
    computed: {
        ...mapGetters({
            customer: 'customer/getCustomer',
        }),
        references() {
            return this.customer?.references ?? [];
        },
    },
    created() {
        this.getRelationTypes();
    },
};
</script>
