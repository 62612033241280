<template>
    <b-overlay :show="loading" rounded="sm">
        <b-card no-body>
            <b-form @submit.prevent="saveData" style="row-gap: 20px" class="d-flex flex-column">
                <div v-if="!readOnly">
                    <Header />
                    <hr class="m-0" />
                </div>
                <template v-if="!loading">
                    <!-- ID Info Section -->
                    <b-card-body class="p-3">
                        <h4>ID Info</h4>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-lg-3">
                                <label class="fw-medium mb-1" for="fname4">National ID</label>
                                <b-form-input
                                    size="sm"
                                    required
                                    :readOnly="readOnly"
                                    type="text"
                                    placeholder="National Id Here"
                                    minLength="8"
                                    maxLength="8"
                                    v-model="dataSelected.nationalID"
                                ></b-form-input>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-3">
                                <label class="fw-medium mb-1" for="fname4">Passport</label>
                                <b-form-input
                                    size="sm"
                                    :readOnly="readOnly"
                                    type="text"
                                    placeholder="Id Here"
                                    v-model="dataSelected.passport"
                                    class="mb-2"
                                ></b-form-input>

                                <label class="fw-medium mb-1" for="fname4">Passport Expiration Date</label>
                                <b-form-input
                                    size="sm"
                                    :readOnly="readOnly"
                                    type="date"
                                    placeholder="Exp. date"
                                    v-model="dataSelected.passportExpirationDate"
                                ></b-form-input>
                            </div>

                            <div class="col-12 col-sm-6 col-lg-3">
                                <label class="fw-medium mb-1" for="fname4">Driver's License</label>
                                <b-form-input
                                    size="sm"
                                    :readOnly="readOnly"
                                    type="text"
                                    placeholder="Id Here"
                                    v-model="dataSelected.driverLicense"
                                    class="mb-2"
                                ></b-form-input>

                                <label class="fw-medium mb-1" for="fname4">Driver's License Expiration Date</label>
                                <b-form-input
                                    size="sm"
                                    :readOnly="readOnly"
                                    v-model="dataSelected.driverLicenseExpirationDate"
                                    type="date"
                                    placeholder="Exp. Date"
                                ></b-form-input>
                            </div>

                            <div class="col-12 col-sm-6 col-lg-3">
                                <label class="fw-medium mb-1" for="fname4">Cedula/National ID</label>
                                <b-form-input
                                    size="sm"
                                    :readOnly="readOnly"
                                    v-model="dataSelected.cedula"
                                    type="text"
                                    placeholder="Id Here"
                                    class="mb-2"
                                ></b-form-input>

                                <label class="fw-medium mb-1" for="fname4">Cedula/National ID Expiration Date</label>
                                <b-form-input
                                    size="sm"
                                    :readOnly="readOnly"
                                    v-model="dataSelected.cedulaExpirationDate"
                                    type="date"
                                    placeholder="Exp. Date"
                                ></b-form-input>
                            </div>
                        </div>
                    </b-card-body>
                    <!-- End Section -->

                    <!-- Personal Section -->

                    <b-card-body class="bg-light p-3 d-flex flex-column" style="row-gap: 12px">
                        <h4 class="mb-0">Personal Info</h4>
                        <b-row>
                            <b-col sm="3" class="">
                                <label class="fw-medium mb-1" for="comp4">Gender</label>
                                <b-form-group v-slot="{ ariaDescribedby }" class="mb-0">
                                    <b-form-radio-group
                                        :options="[
                                            { text: 'Male', value: 'M' },
                                            { text: 'Female', value: 'F' },
                                        ]"
                                        text-field="text"
                                        value-field="value"
                                        :aria-describedby="ariaDescribedby"
                                        name="input-gender"
                                        v-model="dataSelected.gender"
                                        :readOnly="readOnly"
                                    ></b-form-radio-group>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3" class="">
                                <label class="fw-medium mb-1" for="fname4">Title </label>
                                <b-form-select
                                    size="sm"
                                    v-model="customer.titleId"
                                    :disabled="readOnly"
                                    :options="optionsTitle"
                                    required
                                ></b-form-select>
                            </b-col>
                        </b-row>
                        <div class="row" style="row-gap: 12px">
                            <div class="col-12 col-sm-6 col-lg-3">
                                <label class="fw-medium mb-1" for="fname4">Place of Birth</label>
                                <b-form-select
                                    size="sm"
                                    :readOnly="readOnly"
                                    :options="[
                                        { id: null, name: 'Please select an option', disabled: true },
                                        ...countries,
                                    ]"
                                    text-field="name"
                                    value-field="id"
                                    v-model="dataSelected.placeOfBirthId"
                                ></b-form-select>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-3">
                                <label class="fw-medium mb-1" for="fname4">Nationality</label>
                                <b-form-select
                                    size="sm"
                                    required
                                    :readOnly="readOnly"
                                    :options="[
                                        { id: null, name: 'Please select an option', disabled: true },
                                        ...countries,
                                    ]"
                                    text-field="name"
                                    value-field="id"
                                    v-model="dataSelected.nationalityId"
                                ></b-form-select>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-3">
                                <label class="fw-medium mb-1" for="fname4">Date of birth</label>
                                <b-form-input
                                    size="sm"
                                    v-model="dataSelected.birthDate"
                                    :readOnly="readOnly"
                                    required
                                    type="date"
                                ></b-form-input>
                            </div>

                            <div class="col-12 col-sm-6 col-lg-3">
                                <label class="fw-medium mb-1" for="fname4">Age</label>
                                <ItemText :text="age" />
                            </div>

                            <div class="col-12 col-sm-6 col-lg-3">
                                <label class="fw-medium mb-1" for="comp4">Marital Status</label>
                                <b-form-group class="mb-2">
                                    <b-form-select
                                        size="sm"
                                        :readOnly="readOnly"
                                        required
                                        :options="maritalStatus"
                                        text-field="name"
                                        value-field="id"
                                        v-model="dataSelected.maritalStatusId"
                                    ></b-form-select>
                                </b-form-group>

                                <div v-if="dataSelected.maritalStatusId == 1" class="mb-2">
                                    <label class="mb-1">Spouses's</label>
                                    <SearchSelect
                                        store="customer/get_allCustomers"
                                        text="firstName"
                                        value="id"
                                        :defaultOption="spouseSelected"
                                        @option-selected="handleSpouses"
                                    />
                                </div>
                            </div>

                            <div class="col-12 col-sm-6 col-lg-3">
                                <label class="fw-medium mb-1" for="fname4">Number of children</label>
                                <b-form-input
                                    size="sm"
                                    :readOnly="readOnly"
                                    required
                                    min="0"
                                    type="number"
                                    placeholder="Number of children"
                                    v-model="dataSelected.numberDependentPersons"
                                ></b-form-input>
                            </div>

                        </div>
                    </b-card-body>
                    <!-- End Section -->

                    <!-- Bank Info -->
                    <b-card-body class="p-3">
                        <h4>Bank Info</h4>

                        <div class="row">
                            <div class="col-12 col-sm-6 col-lg-3">
                                <label class="fw-medium mb-1" for="comp4">Bank 1</label>
                                <b-form-select
                                    size="sm"
                                    :options="banks.filter((e) => e.id != 0)"
                                    text-field="name"
                                    value-field="id"
                                    v-model="dataSelected.bankId"
                                    :readOnly="readOnly"
                                ></b-form-select>
                            </div>
                            <template v-if="dataSelected.bankId != -1">
                                <div class="col-12 col-sm-6 col-lg-3">
                                    <label class="fw-medium mb-1" for="comp4">Bank Account Number 1</label>
                                    <b-form-input
                                        size="sm"
                                        :readOnly="readOnly"
                                        type="text"
                                        placeholder="Id Here"
                                        v-model="dataSelected.bankAccountNumber"
                                    ></b-form-input>
                                </div>
                            </template>

                            <div class="col-12 col-sm-6 col-lg-3">
                                <label class="fw-medium mb-1" for="comp4">Bank 2</label>
                                <b-form-select
                                    size="sm"
                                    :value="null"
                                    :options="[
                                        { id: null, name: 'Please select an option', disabled: true },
                                        ...banks.filter((e) => e.id != 0),
                                    ]"
                                    v-model="dataSelected.bankId2"
                                    text-field="name"
                                    value-field="id"
                                    :readOnly="readOnly"
                                ></b-form-select>
                            </div>
                            <template v-if="dataSelected.bankId2 != -1">
                                <div class="col-12 col-sm-6 col-lg-3">
                                    <label class="fw-medium mb-1" for="comp4">Bank Account Number 2</label>
                                    <b-form-input
                                        size="sm"
                                        :readOnly="readOnly"
                                        v-model="dataSelected.bankAccountNumber2"
                                        type="text"
                                        placeholder="Id Here"
                                    ></b-form-input>
                                </div>
                            </template>
                        </div>
                    </b-card-body>
                    <!-- End Section -->

                    <b-card-body class="p-3">
                        <h4 class="mb-3">References</h4>

                        <div class="row">
                            <div class="col-4" v-for="(reference, idx) in customer.references" :key="reference.id">
                                <h5>REFERENCE {{ idx + 1 }}</h5>
                                <div class="">
                                    <label class="fw-medium mb-1" for="fname4">Name</label>
                                    <ItemText :text="reference.name" />
                                </div>
                                <div class="">
                                    <label class="fw-medium mb-1" for="fname4">Contact Number</label>
                                    <ItemText :text="reference.contactNumber" />
                                </div>

                                <div class="">
                                    <label class="fw-medium mb-1" for="fname4">Address</label>
                                    <ItemText :text="reference.address" />
                                </div>

                                <div class="">
                                    <label class="fw-medium mb-1" for="fname4">Relation to client</label>
                                    <ItemText :text="reference.relationTypeDescription" />
                                </div>
                            </div>
                        </div>
                    </b-card-body>

                    <!-- Actions Section -->
                    <div class="p-35" v-if="!readOnly && hasPermission">
                        <div class="text-right">
                            <b-button variant="danger" :disabled="loadingSave" @click="updateMambuStatus" class="mr-2">
                                <b-spinner small v-if="loadingSave"></b-spinner>
                                <span v-else>Update Mambu Status</span>
                            </b-button>
                            <b-button :disabled="loadingSave" variant="primary" class="px-4" type="submit">
                                <b-spinner small v-if="loadingSave"></b-spinner>
                                <span v-else>Save</span>
                            </b-button>
                        </div>
                    </div>
                    <!-- End Section -->
                </template>
            </b-form>
        </b-card>
    </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/customers/Header.vue';
import ItemText from '../ItemText.vue';
import * as dayjs from 'dayjs';
import SearchSelect from '../SearchSelect.vue';
export default {
    name: 'Customer',
    components: {
        Header,
        ItemText,
        SearchSelect,
    },
    props: {
        new: Boolean,
        readOnly: Boolean,
    },
    data: () => ({
        optionsCountry: [
            { value: null, text: 'Please select an option' },
            { value: 'Aruba', text: 'Aruba' },
            { value: 'US', text: 'Estados Unidos' },
        ],
        optionsTitle: [
            { value: null, text: 'Please select an option' },
            { text: 'Miss', value: 1 },
            { text: 'Ms', value: 2 },
            { text: 'Mr', value: 3 },
            { text: 'Mrs', value: 4 },
        ],
        optionsRelationship: [
            { value: null, text: 'Please select an option' },
            { text: 'Parent (Mother/Father)', value: 1 },
            { text: 'Sibling (Brother/Sister)', value: 2 },
            { text: 'Spouse/Partner', value: 3 },
            { text: 'Child (Son/Daughter)', value: 4 },
            { text: 'Relative (e.g., Cousin, Aunt, Uncle, Grandparent)', value: 5 },
            { text: 'Friend', value: 6 },
            { text: 'Colleague', value: 7 },
            { text: 'Neighbor', value: 8 },
            { text: 'Other', value: 9 },
        ],
        countrySelected: null,
        titleSelected: null,
        spouseSelected: null,
        dataSelected: {
            nationalID: null,
            passport: null,
            passportExpirationDate: null,
            placeOfBirthId: null,
            nationalityId: null,
            birthDate: null,
            gender: null,
            age: null,
            maritalStatusId: null,
            spousesFullName: null,
            spousesId: null,
            bankId: null,
            bankId2: null,
            bankAccountNumber: '',
            existingBankDebt: 0,
            existingDebtAmount: null,
            otherInstitutionName: null,
            coApplicantFirstName: null,
            coApplicantLastName: null,
            coApplicantPhone1: null,
            numberDependentPersons: null,
            driverLicense: null,
            driverLicenseExpirationDate: null,
            cedula: null,
            cedulaExpirationDate: null,
        },
        riskType: null,
        loadingSave: false,

        maritalStatus: [
            { id: null, name: 'Please select an option' },
            {
                id: 1,
                name: 'Casa',
            },
            {
                id: 2,
                name: 'Soltero/-a',
            },
            {
                id: 3,
                name: 'Biba Hunto',
            },
            {
                id: 4,
                name: 'Otro',
            },
        ],

        loading: false,
        loadingDocumentTypes: false,
        loan: null,
    }),
    computed: {
        ...mapGetters({
            customer: 'customer/getCustomer',
            documentTypes: 'documentsTypes/getDocumentsTypes',
            countries: 'general/countries/getCountries',
            cities: 'general/cities/getCities',
            banks: 'general/banks/getBanksDDL',
            personIdentificationTypes: 'customer/personIdentificationTypes/getPersonIdentificationTypes',
            authorizations: 'auth/getAuthorizations',
        }),
        hasPermission() {
            return this.authorizations.includes('/LoanApplications-Additional-Details');
        },
        age() {
            const birthDate = new Date(this.dataSelected.birthDate);
            const ageDifMs = Date.now() - birthDate.getTime();
            const ageDate = new Date(ageDifMs);
            return Math.abs(ageDate.getUTCFullYear() - 1970);
        },
    },
    methods: {
        formatDate(date) {
            if (!date) return;

            return dayjs(date).format('YYYY-MM-DD');
        },
        async getData() {
            await this.$store.dispatch('customer/personIdentificationTypes/get_person_identification_types');
            await this.$store.dispatch('general/countries/get_allCountries', { skip: 1, take: 9999 });
            await this.$store.dispatch('general/cities/get_allCities', { skip: 1, take: 9999 });
            await this.$store.dispatch('general/banks/get_allBanksDDL');
        },
        async spousesDetail() {
            if (this.dataSelected.spousesId) {
                const spouse = await this.$store.dispatch('customer/get_reference', this.dataSelected.spousesId);
                this.spouseSelected = spouse;
            }
        },

        async getAdditionalDetails() {
            this.loading = true;
            await this.getData();

            this.riskType = this.customer.riskTypeId;
            this.dataSelected = this.customer.additionalDetails;
            await this.spousesDetail();
            this.dataSelected.birthDate = this.dataSelected.birthDate?.split('T')[0];
            this.dataSelected.passportExpirationDate = this.dataSelected.passportExpirationDate?.split('T')[0];
            this.dataSelected.driverLicenseExpirationDate =
                this.dataSelected.driverLicenseExpirationDate?.split('T')[0];
            this.dataSelected.cedulaExpirationDate = this.dataSelected.cedulaExpirationDate?.split('T')[0];

            this.loading = false;
        },
        async saveData() {
            if (this.dataSelected.nationalID == 'NOT DEFINED') {
                this.$bvToast.toast('The national ID is required', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
                return;
            }
            this.loadingSave = true;

            const payload = {
                ...this.customer,
                riskTypeId: this.riskType,
                additionalDetails: this.dataSelected,
            };

            await this.$store.dispatch('customer/update_customer', payload);
            await this.$store.dispatch('customer/productRequest/get_processResquest', this.$route.params.customer);

            this.loadingSave = false;
        },

        async updateMambuStatus() {
            this.loadingSave = true;
            await this.$store.dispatch('mambu/update_mambuCustomer', this.$route.params.customer);
            this.loadingSave = false;
        },

        handleSpouses(item) {
            if (item) {
                this.dataSelected.spousesId = item.id;
            }
        },
    },
    created() {
        this.getAdditionalDetails();
    },
};
</script>
