<template>
    <b-card no-body class="d-flex flex-column p-3" style="row-gap: 16px">
        <div>
            <Header />
            <hr class="m-0" />
        </div>
        <h4>Loans</h4>
        <b-row>
            <b-col cols="12">
                <b-table
                    small
                    show-empty
                    :filter="filter"
                    striped
                    head-variant="light"
                    :items="loans"
                    hover
                    :fields="[
                        { key: 'id', label: 'Loan ID' },
                        { key: 'loanName', label: 'Loan Type' },
                        { key: 'loanAmount', label: 'Loan Amount' },
                        { key: 'creationDate', label: 'Creation Date' },
                        { key: 'approvedDate', label: 'Approved Date' },
                        { key: 'closedDate', label: 'Status' },
                        { key: 'action', label: 'Action' },
                    ]"
                >
                    <template #cell(loanAmount)="data"> AWG {{ data.item.loanAmount }} </template>
                    <template #cell(creationDate)="data">
                        {{ formatDate(data.item.creationDate) }}
                    </template>
                    <template #cell(approvedDate)="data">
                        {{ formatDate(data.item.approvedDate) }}
                    </template>
                    <template #cell(closedDate)="data">
                        <b-badge variant="success" v-if="!data.item.closedDate">Active</b-badge>
                        <b-badge variant="danger" v-else>Inactive</b-badge>
                    </template>
                    <template #cell(action)="data">
                        <b-button size="sm" variant="primary" @click="handleView(data.item)">View Detail</b-button>
                    </template>
                </b-table>

                <div class="pt-2 d-flex align-items-center">
                    <b-form-group
                        label="Per page"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="9"
                        label-size="sm"
                        label-class="fw-medium"
                        class="mb-0"
                    >
                        <b-form-select
                            id="per-page-select"
                            v-model="perPage"
                            :options="pageOptions"
                            size="sm"
                        ></b-form-select>
                    </b-form-group>
                    <div class="ml-auto">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="loans.length"
                            :per-page="perPage"
                            align="fill"
                            class="my-0"
                        ></b-pagination>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import Header from '@/components/customers/Header.vue';
import * as dayjs from 'dayjs';
import { mapGetters } from 'vuex';
export default {
    name: 'ApplicationHistory',
    components: {
        Header,
    },
    data() {
        return {
            loans: [],
            perPage: 5,
            currentPage: 1,
            pageOptions: [5, 10, 15],
            filter: null,
        };
    },
    computed: {
        ...mapGetters({
            customer: 'customer/getCustomer',
        }),
    },
    methods: {
        async fetchLoans() {
            if (!this.customer.encodedKey) {
                return;
            }
            const response = await this.$store.dispatch('mambu/get_loansByEncodedKey', this.customer.encodedKey);
            this.loans = response?.data?.dataResult ?? [];
        },
        formatDate(date) {
            if (!date) return;
            return dayjs(date).format('YYYY-MM-DD');
        },
        handleView(item) {
            this.$router.push(`/loans/all/${item.id}`);
        },
    },
    created() {
        this.fetchLoans();
    },
};
</script>
