var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"d-flex flex-column p-3",staticStyle:{"row-gap":"16px"},attrs:{"no-body":""}},[_c('div',[_c('Header'),_c('hr',{staticClass:"m-0"})],1),_c('h4',[_vm._v("Loans")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-table',{attrs:{"small":"","show-empty":"","filter":_vm.filter,"striped":"","head-variant":"light","items":_vm.loans,"hover":"","fields":[
                    { key: 'id', label: 'Loan ID' },
                    { key: 'loanName', label: 'Loan Type' },
                    { key: 'loanAmount', label: 'Loan Amount' },
                    { key: 'creationDate', label: 'Creation Date' },
                    { key: 'approvedDate', label: 'Approved Date' },
                    { key: 'closedDate', label: 'Status' },
                    { key: 'action', label: 'Action' } ]},scopedSlots:_vm._u([{key:"cell(loanAmount)",fn:function(data){return [_vm._v(" AWG "+_vm._s(data.item.loanAmount)+" ")]}},{key:"cell(creationDate)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.formatDate(data.item.creationDate))+" ")]}},{key:"cell(approvedDate)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.formatDate(data.item.approvedDate))+" ")]}},{key:"cell(closedDate)",fn:function(data){return [(!data.item.closedDate)?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v("Active")]):_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("Inactive")])]}},{key:"cell(action)",fn:function(data){return [_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.handleView(data.item)}}},[_vm._v("View Detail")])]}}])}),_c('div',{staticClass:"pt-2 d-flex align-items-center"},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Per page","label-for":"per-page-select","label-cols-sm":"6","label-cols-md":"4","label-cols-lg":"9","label-size":"sm","label-class":"fw-medium"}},[_c('b-form-select',{attrs:{"id":"per-page-select","options":_vm.pageOptions,"size":"sm"},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1),_c('div',{staticClass:"ml-auto"},[_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.loans.length,"per-page":_vm.perPage,"align":"fill"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }